import { useConnectedWallet, useWallet } from "@xpla/wallet-provider";
import { useCallback, useContext, useState } from "react";
import XplaConnectWalletDialog from "./XplaConnectWalletDialog";
import ToggleConnectedButton from "./ToggleConnectedButton";
import { ConnectionContext } from "../contexts/XplaWalletContext";


const XplaWalletKey = ({setWalletReady, isReady} : {setWalletReady: any, isReady: boolean}) => {
  const {account, connect, disconnect} = useContext(ConnectionContext);
  // const wallet = useWallet();
  // const connectedWallet = useConnectedWallet();

  // const [isDialogOpen, setIsDialogOpen] = useState(false);

  // const connect = async () => {
  //   const tron = window.tronLink;
  //   try {
  //     await tron.request({method: 'tron_requestAccounts'});
  //     const _tronWeb = tron.tronWeb;
  //     if (window.tronLink.tronWeb?.defaultAddress) {
  //       setWalletReady(true);
  //     }
  //   } catch (err) {
  //     console.log("error with connect wallet", err);
  //   }
  //   // console.log("#1", tronWeb.defaultAddress.base58 || undefined);
  // }

  return (
    <>
      <ToggleConnectedButton
        connect={connect}
        disconnect={disconnect}
        connected={account != undefined}
        pk={account ? account : ""}
      />
      {/* <XplaConnectWalletDialog isOpen={isDialogOpen} onClose={closeDialog} /> */}
    </>
  );
};

export default XplaWalletKey;
