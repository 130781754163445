import { NetworkInfo, WalletProvider } from "@xpla/wallet-provider";
import { createContext, ReactChildren, useState } from "react";
// @ts-expect-error import tron
import TronWeb from "tronweb";

const testnet: NetworkInfo = {
  name: "testnet",
  chainID: "cube_47-5",
  lcd: "https://cube-lcd.xpla.dev",
  walletconnectID: 0,
};

const walletConnectChainIds: Record<number, NetworkInfo> = {
  0: testnet,
};

interface ConnectContextI {
  account?: string,
  walletProvider?: any,
  connect: () => Promise<void>,
  disconnect: () => Promise<void>,
}

const defaultValue: ConnectContextI = {
  connect: () => Promise.resolve(),
  disconnect: () => Promise.resolve(),
}

export const ConnectionContext = createContext<ConnectContextI>(defaultValue);

interface TronLinkParams {
  request: (args: any) => any;
  tronWeb: TronWeb;
}

declare global {
  interface Window {
      tronLink: TronLinkParams;
  }
}

export const XplaWalletProvider = ({
  children,
}: {
  children: ReactChildren;
}) => {
  const [account, setAccount] = useState<string | undefined>();
  const [walletProvider, setWalletProvider] = useState<any>();

  const connect = async (): Promise<void> => {
    try {
        const wProvider = window.tronLink;
        await wProvider.request({method: 'tron_requestAccounts'});
        const account = wProvider.tronWeb.defaultAddress.base58 || undefined;
        setAccount(account);
        setWalletProvider(wProvider);
    } catch (error) {
        console.error('Connection error', error);
        disconnect();
    }

  }

  const disconnect = async (): Promise<void> => {
      setAccount(undefined);
      setWalletProvider(undefined);
  }

  return (
    <WalletProvider
      defaultNetwork={testnet}
      walletConnectChainIds={walletConnectChainIds}
    >
      <ConnectionContext.Provider value={{account, connect, disconnect, walletProvider}}>
        {children}
      </ConnectionContext.Provider>
    </WalletProvider>
  );
};

export default XplaWalletProvider;
