import { Button, makeStyles, TextField, Typography } from "@material-ui/core";
import { ContactSupportOutlined, VerifiedUser } from "@material-ui/icons";
import { ChangeEvent, useCallback, useMemo, useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useIsWalletReady from "../../hooks/useIsWalletReady";
import {
  selectTransferAmount,
  selectTransferIsSourceComplete,
  selectTransferShouldLockFields,
  selectTransferSourceBalanceString,
  selectTransferSourceChain,
  selectTransferSourceError,
  selectTransferSourceParsedTokenAccount,
  selectTransferTargetChain,
} from "../../store/selectors";
import {
  incrementStep,
  setAmount,
  setSourceChain,
  setTargetChain,
} from "../../store/transferSlice";
import { CHAINS, CLUSTER, getIsTransferDisabled, SOLANA_HOST, SOLANA_TOKEN_METADATA_PROGRAM_URL } from "../../utils/consts";
import ButtonWithLoader from "../ButtonWithLoader";
import ChainSelect from "../ChainSelect";
import ChainSelectArrow from "../ChainSelectArrow";
import KeyAndBalance from "../KeyAndBalance";
import LowBalanceWarning from "../LowBalanceWarning";
import NumberTextField from "../NumberTextField";
import StepDescription from "../StepDescription";
import { TokenSelector } from "../TokenSelectors/SourceTokenSelector";
import SourceAssetWarning from "./SourceAssetWarning";
import ChainWarningMessage from "../ChainWarningMessage";
import useIsTransferLimited from "../../hooks/useIsTransferLimited";
import TransferLimitedWarning from "./TransferLimitedWarning";
// import * as anchor from '@coral-xyz/anchor'
import { web3 } from '@coral-xyz/anchor'
import { ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID} from '@solana/spl-token'
import { Keypair, PublicKey, SystemProgram, Connection, ComputeBudgetProgram, sendAndConfirmTransaction, Transaction } from '@solana/web3.js'
import { OftTools } from '@layerzerolabs/lz-solana-sdk-v2'
import {addressToBytes32} from '@layerzerolabs/lz-v2-utilities';
import {Options} from '@layerzerolabs/lz-v2-utilities';
import { bs58 } from "@coral-xyz/anchor/dist/cjs/utils/bytes";
import { useSolanaWallet } from "../../contexts/SolanaWalletContext";
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { PhantomWalletAdapter } from "@solana/wallet-adapter-wallets";
import { setBalance } from "../../store/usdcSlice";
import { Web3 } from 'web3';
import { useEthereumProvider } from "../../contexts/EthereumProviderContext";
import abiBase from '../../utils/abi_base.json';
import abiTron from '../../utils/abi_tron.json';
import { ethers_contracts } from "@certusone/wormhole-sdk";
import { ethers } from "ethers";
// @ts-expect-error import tron
import TronWeb from "tronweb";
import {ConnectionContext} from "../../contexts/XplaWalletContext";

export interface CustomWalletAdapter extends PhantomWalletAdapter {
    signAndSendTransaction(transaction: web3.Transaction): Promise<web3.Transaction>
}

export function getPhantomAdapter() {
    if ("solana" in window) {
        const provider = (window as any).solana;
        if (provider?.isPhantom) {
            return provider;
        }
    }
    window.open("https://phantom.app/", "_blank");
};
const OFT_SEED = 'Oft'

const useStyles = makeStyles((theme) => ({
  chainSelectWrapper: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  chainSelectContainer: {
    flexBasis: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  chainSelectArrow: {
    position: "relative",
    top: "12px",
    [theme.breakpoints.down("sm")]: { transform: "rotate(90deg)" },
  },
  transferField: {
    marginTop: theme.spacing(5),
  },
}));

interface TronLinkParams {
  request: (args: any) => any;
  tronWeb: TronWeb;
}

function Source() {
  const {account, connect, disconnect, walletProvider} = useContext(ConnectionContext);
  const { publicKey } = useSolanaWallet();
  const { provider } = useEthereumProvider();
  const { signer, signerAddress } = useEthereumProvider();
  // console.log('signerAddress', signerAddress);
  const classes = useStyles();
  const dispatch = useDispatch();
  const sourceChain = useSelector(selectTransferSourceChain);
  // console.log('sourceChain', sourceChain);
  const targetChain = useSelector(selectTransferTargetChain);
  const targetChainOptions = useMemo(
    () => CHAINS.filter((c) => c.id !== sourceChain),
    [sourceChain]
  );
  const isSourceTransferDisabled = useMemo(() => {
    return getIsTransferDisabled(sourceChain, true);
  }, [sourceChain]);
  const isTargetTransferDisabled = useMemo(() => {
    return getIsTransferDisabled(targetChain, false);
  }, [targetChain]);
  const parsedTokenAccount = useSelector(
    selectTransferSourceParsedTokenAccount
  );
  const hasParsedTokenAccount = true//!!parsedTokenAccount;
  const uiAmountString = useSelector(selectTransferSourceBalanceString);
  const amount = useSelector(selectTransferAmount);
  const [recAddress, setRecAddress] = useState('');
  const error = useSelector(selectTransferSourceError);
  const isSourceComplete = useSelector(selectTransferIsSourceComplete);
  const shouldLockFields = useSelector(selectTransferShouldLockFields);
  const { isReady, statusMessage, walletAddress } = useIsWalletReady(sourceChain);
  const isTransferLimited = useIsTransferLimited();

  const { wallet, sendTransaction } = useWallet();
  const { connection } = useConnection();

  const [isTronReady, setIsTronReady] = useState(false);
  const [targetId, setTargetId] = useState<any>();

  const handleSourceChange = useCallback(
    (event) => {
      dispatch(setSourceChain(event.target.value));
    },
    [dispatch]
  );
  const handleTargetChange = useCallback(
    (event) => {
      dispatch(setTargetChain(event.target.value));
    },
    [dispatch]
  );
  const handleAmountChange = useCallback(
    (event) => {
      dispatch(setAmount(event.target.value));
    },
    [dispatch]
  );
  const handleAddressChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    // console.log(e.target.value);
    setRecAddress(e.target.value);
  }
  const handleMaxClick = useCallback(() => {
    if (uiAmountString) {
      dispatch(setAmount(uiAmountString));
    }
  }, [dispatch, uiAmountString]);

  const [isSending, setIsSending] = useState(false);
  const [balanceAmount, setBalanceAmount] = useState('0');
  const handleSendClick = async () => {
    try {
      setIsSending(true);
      if (sourceChain == 1) {
        const connectionT = new Connection('https://stylish-smart-rain.solana-mainnet.quiknode.pro/2fec5dfe4fca7a21bef47aac8d38b4c905de369d');//new Connection('https://api.mainnet-beta.solana.com');
        const payer = new PublicKey(publicKey?.toBase58() || "");
        // console.log(publicKey?.toBase58() || "");
        const OFT_PROGRAM_ID = new PublicKey('HNcGgAkDRE9Eh7qMj39WJ4Pgv9EB6y7BEbwEKf2SZTzN')//97GcgUvT55eqER9D3YpjvyC8x8YiEp9tkKQXZaFVs1z7
        const ENDPOINT_PROGRAM_ID = new PublicKey('76y77prsiCMvXMjuoZ5VRrhG5qYBrUMYTE5WgHqgjEn6');
        const LOCAL_DECIMALS = 9;
        const SHARED_DECIMALS = 6;
        const peers = [
          {
            dstEid: 30184,
            peerAddress: addressToBytes32('0x14DC6E76DCE0D4C48a6e768Be9af62D1341040eA'),//('0x790393A22FC1Cd323B97395BF91F9D3CEBE4E775'),//0xF470eE73578e5a787F194D837c703F73A6eD0cD3
          },
          {
            dstEid: 30420,
            peerAddress: addressToBytes32('0x03d141b7525adc4c03a42419eeaf01bf2c86db9c'),
          }
        ];
        let peer : any;
        if (targetChain == 30) {
          peer = peers[0];
        } else if (targetChain == 28) {
          peer = peers[1];
        }

        const mintKp = new PublicKey('ERAVJmgPNMh3Wpj9zZ9Hb6vZxjFpiCckUErXnACop1kA');//55YC4JLsvKNFfRRtHg8pp9tBDnA2Va8Bbv7fH2dGA8tx
        // const lockBox = Keypair.generate();//TowXSweqou37RzvLTYhHjbt1zBy3LuBrCVgdortajHzKV1B89sP5JDWcMTpP5dmdjgDmsfW2j5njwSNFWJNekme
        const lockBox = Keypair.fromSecretKey(Uint8Array.from(bs58.decode('5sM7vv5YridVfibydNsCB2VBkryARnPGMvQsYu1HHAziTwKXa72B8khWAz7jZxV61fQJw1o81CDPGQDVQdC4eE5f')));
        
        let receiver : any;
        if (targetChain == 30) {
          receiver = addressToBytes32(recAddress);
        } else if (targetChain == 28) {
          receiver = addressToBytes32('0x' + TronWeb.address.toHex(recAddress).slice(2));
        }
        
        const oftConfig = new PublicKey('4bNcLvSu7k8RZXkKHrr4r84VB6s69kEPGHbz5C7p3T7H');//ERzJdMdXMxggUyNtYavjKTMht8KhX7QamvgFKnaao5v6

        const amountToSend = BigInt(parseFloat(amount) * (10 ** LOCAL_DECIMALS));
        console.log(amountToSend);

        // const associatedTokenAccount = 
        const [associatedTokenAccount] = PublicKey.findProgramAddressSync(
          [payer.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), mintKp.toBuffer()],
          ASSOCIATED_TOKEN_PROGRAM_ID
        );

        const fee = await OftTools.quoteWithUln(
            connectionT,
            OFT_PROGRAM_ID,
            payer, // the payer's address
            mintKp, // your token mint account
            peer.dstEid, // the dstEid
            amountToSend, // the amount of tokens to send
            BigInt(0), // the minimum amount of tokens to send (for slippage)
            Options.newOptions().addExecutorLzReceiveOption(200000, 0).toBytes(), // any extra execution options to add on top of enforced
            Array.from(receiver), // the receiver's address in bytes32
            false,
            lockBox.publicKey,
            undefined,
            Array.from(peer.peerAddress),
        );
        console.log('fee: ', fee);
        const modifyComputeUnits = ComputeBudgetProgram.setComputeUnitLimit({ 
            units: 1000000 
          });
          
          const addPriorityFee = ComputeBudgetProgram.setComputeUnitPrice({ 
            microLamports: 1 
          });
        const sendTransaction = new Transaction()
        .add(modifyComputeUnits)
        .add(addPriorityFee)        
        .add(
            await OftTools.sendWithUln(
              connectionT, // your connection
              OFT_PROGRAM_ID,
              payer, // payer address
              mintKp, // token mint address
              associatedTokenAccount, // associated token address
              peer.dstEid, // destination endpoint id
              amountToSend, // amount of tokens to send
              BigInt(0), // minimum amount of tokens to send (for slippage)
              Options.newOptions().addExecutorLzReceiveOption(200000, 0).toBytes(), // extra options to send
              Array.from(receiver), // receiver address
              fee.nativeFee, // native fee to pay (using quote)
              undefined,
              lockBox.publicKey,
              undefined,
              Array.from(peer.peerAddress),
              undefined,
              ENDPOINT_PROGRAM_ID,
              TOKEN_PROGRAM_ID,
            ),
        )

        sendTransaction.recentBlockhash = (await connectionT.getLatestBlockhash()).blockhash;
        sendTransaction.feePayer = payer;
        // Sign and send the transaction
        try {
          const tx = await getPhantomAdapter().signTransaction(
              sendTransaction
          );
          const hash = await connectionT.sendRawTransaction(tx.serialize(), {maxRetries: 3, skipPreflight: true})
          await connectionT.confirmTransaction(hash)
          console.log('Transaction confirmed with signature:', tx);
        } catch (error) {
            console.error('Transaction failed:', error);
        }
        // await provider.sendAndConfirm(sendTransaction, [wallet.payer]);
      } else if (sourceChain == 30) {
        const endpointUrl = "https://base.llamarpc.com"
        const httpProvider = new Web3.providers.HttpProvider(endpointUrl);
        const web3Client = new Web3(httpProvider);

        // console.log('debug', signer, signerAddress)
        const tokenAddress = "0x14DC6E76DCE0D4C48a6e768Be9af62D1341040eA";

        // const account = web3Client.eth.accounts.wallet[0].sign(walletAddress || '');
        // const userAddress = account[0].address
        
        const contract = new web3Client.eth.Contract(abiBase, tokenAddress);

        let sendParam = [];
        if (targetChain == 1) {
          sendParam.push('30168');
        } else if (targetChain == 28) {
          sendParam.push('30420');
        }
        let receiver : any;
        if (targetChain == 1) {
          receiver = addressToBytes32(recAddress);
        } else if (targetChain == 28) {
          receiver = addressToBytes32('0x' + TronWeb.address.toHex(recAddress).slice(2));
        }
        sendParam.push(receiver);
        // console.log('addressToBytes32(recAddress)', addressToBytes32(recAddress));
        sendParam.push((BigInt(amount) * BigInt(10 ** 18)).toString());
        sendParam.push('0');
        sendParam.push('0x');
        sendParam.push('0x');
        sendParam.push('0x');
        console.log('sendParam', sendParam);

        const result = await contract.methods.quoteSend(sendParam, 0).call();
        console.log(`quoteSend : ${(result as any)[0]}`);

        console.log(sendParam, ['' + (result as any)[0], '0'], walletAddress);
        console.log('' + (parseFloat((result as any)[0]) / (10 ** 18)).toString());

        // const transactionResponse = await contract.methods.send(sendParam, ['' + (result as any)[0], '0'], walletAddress).send({
        //   from: web3Client.eth.accounts.wallet[0].address,
        //   value: web3Client.utils.toWei('' + parseFloat((result as any)[0]) / (10 ** 18), 'ether'),
        // })
        // console.log(`Transaction hash: ${transactionResponse.transactionHash}`);

        const oft = new ethers.Contract(tokenAddress, abiBase, signer);//{value: ethers.utils.parseEther(ETH_VALUE_AS_STRING)}
        const tx = await oft.send(sendParam, ['' + (result as any)[0], '0'], walletAddress, {value: ethers.utils.parseEther('' + (parseFloat((result as any)[0]) / (10 ** 18)).toString())});
        await tx.wait();
      } else if (sourceChain == 28) {
        if (!walletProvider || !account) {
          // console.log("#1 not Ready!!!");
          setIsSending(false);   
          return;
        }
        const tronWeb = walletProvider.tronWeb;
        // console.log("#2", tronWeb.defaultAddress.base58);
        let contract = await tronWeb.contract(abiTron, 'TAKPi3a7oNtBa9zK8iBMY5CrHuDaDHvV1A');
        let sendParam = [];
        // console.log(targetChain);
        if (targetChain == 1) {
          sendParam.push('30168');
        } else if (targetChain == 30) {
          sendParam.push('30184');
        }
        sendParam.push(addressToBytes32(recAddress));
        // console.log('addressToBytes32(recAddress)', addressToBytes32(recAddress));
        sendParam.push((BigInt(amount) * BigInt(10 ** 18)).toString());
        sendParam.push('0');
        sendParam.push('0x');
        sendParam.push('0x');
        sendParam.push('0x');
        console.log('sendParam', sendParam);

        const result = await contract.quoteSend(sendParam, 0).call();
        console.log(`quoteSend : ${(result as any).toString().split(',')[0]}`);
        const fee = (result as any).toString().split(',')[0];
        await contract.send(sendParam, [fee, '0'], tronWeb.defaultAddress.base58).send({
          // feeLimit:100_000_000,
          callValue: parseFloat(fee),
          shouldPollResponse:true
        });
        // const signedTx = await tronWeb.trx.sign(tx);
        // await tronWeb.trx.sendRawTransaction(signedTx);
      }
      setIsSending(false);    
    } catch (err) {
      console.log("error: ", err);
      setIsSending(false);    
    }
  }

  useEffect(() => {
    if (sourceChain == 1) {
      if (publicKey == null) {
        setBalanceAmount('0');
        return;
      }
      // console.log("provider", provider);
      const fetchBalance = async () => {
        const connection = new Connection('https://stylish-smart-rain.solana-mainnet.quiknode.pro/2fec5dfe4fca7a21bef47aac8d38b4c905de369d');
        const accountPublicKey = new PublicKey(
          publicKey.toBase58()
        );
        const mintAccount = new PublicKey(
          'ERAVJmgPNMh3Wpj9zZ9Hb6vZxjFpiCckUErXnACop1kA'
        );
        const account = await connection.getTokenAccountsByOwner(accountPublicKey, {
          mint: mintAccount});
        if (account.value.length === 0) {
          setBalanceAmount('0');
        } else {
          const balance = await connection.getTokenAccountBalance(new PublicKey(account.value[0].pubkey.toString()));
          console.log('balance', balance.value.uiAmount);
          setBalanceAmount('' + balance.value.uiAmount);
        }
      }
      fetchBalance();
    }
    else if (sourceChain == 30) {
      if (!isReady) {
        setBalanceAmount('0');
        // console.log("----!@#");
        return;
      }

      const endpointUrl = "https://base-pokt.nodies.app"
      const httpProvider = new Web3.providers.HttpProvider(endpointUrl);
      const web3Client = new Web3(httpProvider);

      const tokenAddress = "0x14DC6E76DCE0D4C48a6e768Be9af62D1341040eA";
      
      const contract = new web3Client.eth.Contract(abiBase, tokenAddress);

      const getBalance = async () => {
        const result = await contract.methods.balanceOf(walletAddress).call();
        const resultInEther = web3Client.utils.fromWei('' + result, "ether");
        // console.log(`Balance in wei: ${result}`);
        // console.log(`Balance in ether: ${resultInEther}`);
        setBalanceAmount(`${resultInEther}`);
      }
      
      getBalance();
    }
    else if (sourceChain == 28) {
      if (!walletProvider || !account) {
        setBalanceAmount('0');
        // console.log("#1 not Ready!!!");
        return;
      }
      const tronWeb = walletProvider.tronWeb;
      // console.log("#2", tronWeb.defaultAddress.base58);
      const getBalance = async () => {
        let contract = await tronWeb.contract(abiTron, 'TAKPi3a7oNtBa9zK8iBMY5CrHuDaDHvV1A');
        let result = await contract.balanceOf(tronWeb.defaultAddress.base58).call();
        setBalanceAmount((parseFloat(result.toString(10)) / (10 ** 18)).toString());
      }
      getBalance();
    }
  }, [isSending, publicKey, sourceChain, isReady, account, walletProvider])

  useEffect(() => {
    setTargetId(targetChain);
  }, [targetChain])
  
  const onMaxClick = useCallback(
    () => {
      dispatch(setAmount(balanceAmount));
    },
    [dispatch, balanceAmount]
  );

  return (
    <>
      <StepDescription>
        <div style={{ display: "flex", alignItems: "center" }}>
          Send EGOs through the LayerZero.
          <div style={{ flexGrow: 1 }} />
          <div>
            {/* <Button
              component={Link}
              to="/token-origin-verifier"
              size="small"
              variant="outlined"
              startIcon={<VerifiedUser />}
            >
              Token Origin Verifier
            </Button> */}
          </div>
        </div>
      </StepDescription>
      <div
        className={classes.chainSelectWrapper}
        style={{ marginBottom: "25px" }}
      >
        <div className={classes.chainSelectContainer}>
          <Typography variant="caption">Source</Typography>
          <ChainSelect
            select
            variant="outlined"
            fullWidth
            value={sourceChain}
            onChange={handleSourceChange}
            disabled={shouldLockFields}
            chains={CHAINS}
          />
        </div>
        <div className={classes.chainSelectArrow}>
          <ChainSelectArrow
            onClick={() => {
              dispatch(setSourceChain(targetChain));
            }}
            disabled={shouldLockFields}
          />
        </div>
        <div className={classes.chainSelectContainer}>
          <Typography variant="caption">Target</Typography>
          <ChainSelect
            variant="outlined"
            select
            fullWidth
            value={targetChain}
            onChange={handleTargetChange} 
            disabled={shouldLockFields}
            chains={targetChainOptions}
          />
        </div>
      </div>
      <KeyAndBalance chainId={sourceChain} setIsReady={setIsTronReady} isReady={isTronReady} />
      {/* {isReady || uiAmountString ? (
        <div className={classes.transferField}>
          <TokenSelector disabled={shouldLockFields} />
        </div>
      ) : null} */}
      <LowBalanceWarning chainId={sourceChain} targetId={targetId} />
      <SourceAssetWarning
        sourceChain={sourceChain}
        sourceAsset={parsedTokenAccount?.mintKey}
      />
      <div style={{ display: "flex", alignItems: "center", marginTop: '30px' }}>
        <div style={{ flexGrow: 1 }} />
        <div>
          {'Balance: ' + balanceAmount}
        </div>
      </div>
      {hasParsedTokenAccount ? (
        <div style={{ marginTop: '-30px' }}>
        <NumberTextField
          variant="outlined"
          label="Amount"
          fullWidth
          className={classes.transferField}
          value={amount}
          onChange={handleAmountChange}
          disabled={shouldLockFields}
          onMaxClick={
            (publicKey || isReady) && parseFloat(balanceAmount) > 0
              ? onMaxClick
              : undefined
          }
        />
        <TextField
          variant="outlined"
          label="Destination Address"
          fullWidth
          className={classes.transferField}
          value={recAddress}
          onChange={(e) => handleAddressChange(e)}
          disabled={shouldLockFields}
        />          
        </div>
      ) : null}
      <ChainWarningMessage chainId={sourceChain} />
      <ChainWarningMessage chainId={targetChain} />
      <TransferLimitedWarning isTransferLimited={isTransferLimited} />
      <ButtonWithLoader
        disabled={
          recAddress == '' ||
          isSourceTransferDisabled ||
          isTargetTransferDisabled ||
          balanceAmount == '0' ||
          !(parseFloat(amount) > 0 && parseFloat(amount) <= parseFloat(balanceAmount)) ||
          isSending ||
          (sourceChain == 28 && (!account || !walletProvider))
        }
        onClick={handleSendClick}
        showLoader={false}
        // error={statusMessage || error}
      >
        {
          isSending ?
            "Processing..." :
            "Send"
        }
      </ButtonWithLoader>
    </>
  );
}

export default Source;
